@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&family=Syne:wght@400;500;600;700;800&display=swap');

:root {
  --bg-gradient-start: #ffffff;
  --bg-gradient-end: #f0f0f0;
}

.dark {
  --bg-gradient-start: #1a1a1a;
  --bg-gradient-end: #2d2d2d;
}

body {
  margin: 0;
  font-family: 'Space Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Syne', sans-serif;
}

/* Split Text Animation */
.split-text {
  overflow: hidden;
}

.split-text span {
  display: inline-block;
  transform: translateY(100%);
  animation: splitTextReveal 0.8s cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

@keyframes splitTextReveal {
  to {
    transform: translateY(0);
  }
}

/* Smooth Scroll */
html {
  scroll-behavior: smooth;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--bg-gradient-start);
}

::-webkit-scrollbar-thumb {
  background: #FF6F3C;
  border-radius: 4px;
}

/* Dark mode enhancements */
.dark body {
  background-color: var(--bg-gradient-start);
}

/* Image hover effects */
.image-hover {
  position: relative;
  overflow: hidden;
}

.image-hover img {
  transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.image-hover:hover img {
  transform: scale(1.1);
}

/* Text gradient */
.text-gradient {
  background: linear-gradient(90deg, #FF6F3C, #3D9CA8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Loading animation */
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--bg-gradient-start);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-text {
  font-size: 2rem;
  font-weight: 700;
  color: #FF6F3C;
  animation: loadingPulse 1.5s infinite;
}

@keyframes loadingPulse {
  0%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.95);
  }
}

/* Page transitions */
.page-transition-enter {
  opacity: 0;
  transform: translateY(20px);
}

.page-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s, transform 0.5s;
}

.page-transition-exit {
  opacity: 1;
  transform: translateY(0);
}

.page-transition-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.5s, transform 0.5s;
}

/* Light/Dark Theme Colors */
:root {
  --color-primary: #1A2B4A;
  --color-secondary: #FF6F3C;
  --color-accent: #3D9CA8;
  --color-background: #ffffff;
  --color-text: #2d2d2d;
  --color-text-light: #6b7280;
}

.dark {
  --color-primary: #1a1a1a;
  --color-secondary: #FF6F3C;
  --color-accent: #3D9CA8;
  --color-background: #121212;
  --color-text: #ffffff;
  --color-text-light: #9ca3af;
}

/* Apply theme colors */
.bg-primary { background-color: var(--color-primary); }
.bg-secondary { background-color: var(--color-secondary); }
.bg-accent { background-color: var(--color-accent); }
.bg-background { background-color: var(--color-background); }

.text-primary { color: var(--color-text); }
.text-secondary { color: var(--color-secondary); }
.text-accent { color: var(--color-accent); }
.text-text { color: var(--color-text); }
.text-text-light { color: var(--color-text-light); }
